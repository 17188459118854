export default function TwitterIcon() {
	return (
      <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
         <g id="1440*900-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1440*900-Home:full:color2" transform="translate(-942.000000, -548.000000)" fill="currentColor" fillRule="nonzero">
               <g id="contents" transform="translate(180.000000, 240.000000)">
                  <g id="row" transform="translate(294.000000, 294.000000)">
                     <g id="social" transform="translate(380.000000, 12.250000)">
                        <path
                           d="M112.000915,4.309 C111.117915,4.701 110.168915,4.965 109.172915,5.084 C110.189915,4.475 110.970915,3.51 111.337915,2.36 C110.386915,2.924 109.332915,3.334 108.210915,3.555 C107.313915,2.598 106.032915,2 104.616915,2 C101.437915,2 99.1019146,4.966 99.8199146,8.045 C95.7289146,7.84 92.1009146,5.88 89.6719146,2.901 C88.3819146,5.114 89.0029146,8.009 91.1949146,9.475 C90.3889146,9.449 89.6289146,9.228 88.9659146,8.859 C88.9119146,11.14 90.5469146,13.274 92.9149146,13.749 C92.2219146,13.937 91.4629146,13.981 90.6909146,13.833 C91.3169146,15.789 93.1349146,17.212 95.2909146,17.252 C93.2209146,18.875 90.6129146,19.6 88.0009146,19.292 C90.1799146,20.689 92.7689146,21.504 95.5489146,21.504 C104.690915,21.504 109.855915,13.783 109.543915,6.858 C110.505915,6.163 111.340915,5.296 112.000915,4.309 Z"
                           id="twitter"
                        ></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
}