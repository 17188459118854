export default function TelegramIcon() {
	return (
      <svg width="24px" height="20px" viewBox="0 0 24 20">
         <title>telegram</title>
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1440*900-Home:full:color2" transform="translate(-898.000000, -548.000000)" fill="currentColor">
               <g id="contents" transform="translate(180.000000, 240.000000)">
                  <g id="row" transform="translate(294.000000, 294.000000)">
                     <g id="social" transform="translate(380.000000, 12.250000)">
                        <path
                           d="M62.3847662,21.5291482 C62.7067662,21.7571482 63.1217662,21.8141482 63.4917662,21.6741482 C63.8617662,21.5331482 64.1337662,21.2171482 64.2157662,20.8341482 C65.0847662,16.7501482 67.1927662,6.41314821 67.9837662,2.69814821 C68.0437662,2.41814821 67.9437662,2.12714821 67.7237662,1.94014821 C67.5037662,1.75314821 67.1987662,1.69914821 66.9267662,1.80014821 C62.7337662,3.35214821 49.8207662,8.19714821 44.5427662,10.1501482 C44.2077662,10.2741482 43.9897662,10.5961482 44.0007662,10.9491482 C44.0127662,11.3031482 44.2507662,11.6101482 44.5937662,11.7131482 C46.9607662,12.4211482 50.0677662,13.4061482 50.0677662,13.4061482 C50.0677662,13.4061482 51.5197662,17.7911482 52.2767662,20.0211482 C52.3717662,20.3011482 52.5907662,20.5211482 52.8797662,20.5971482 C53.1677662,20.6721482 53.4757662,20.5931482 53.6907662,20.3901482 C54.9067662,19.2421482 56.7867662,17.4671482 56.7867662,17.4671482 C56.7867662,17.4671482 60.3587662,20.0861482 62.3847662,21.5291482 Z M51.3747662,12.8521482 L53.0537662,18.3901482 L53.4267662,14.8831482 C53.4267662,14.8831482 59.9137662,9.03214821 63.6117662,5.69714821 C63.7197662,5.59914821 63.7347662,5.43514821 63.6447662,5.32014821 C63.5557662,5.20514821 63.3917662,5.17814821 63.2687662,5.25614821 C58.9827662,7.99314821 51.3747662,12.8521482 51.3747662,12.8521482 Z"
                           id="telegram"
                        ></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
}