export default function GithubIcon() {
	return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
         <title>gh</title>
         <g id="1440*900-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1440*900-Home:full:color2" transform="translate(-854.000000, -546.000000)" fill="currentColor">
               <g id="contents" transform="translate(180.000000, 240.000000)">
                  <g id="row" transform="translate(294.000000, 294.000000)">
                     <g id="social" transform="translate(380.000000, 12.250000)">
                        <path
                           d="M11.9990791,0 C5.37262305,0 0,5.39378938 0,12.0478307 C0,17.3706199 3.43809568,21.885783 8.20663935,23.4795875 C8.80703531,23.5897856 9.02582992,23.2177746 9.02582992,22.8982741 C9.02582992,22.6127945 9.01551637,21.8547204 9.00962291,20.8496252 C5.67171601,21.5773763 4.96744786,19.2343727 4.96744786,19.2343727 C4.42156637,17.8432144 3.63478982,17.471943 3.63478982,17.471943 C2.54523689,16.7249627 3.71729822,16.7397544 3.71729822,16.7397544 C4.92177356,16.8248066 5.55532023,17.9815167 5.55532023,17.9815167 C6.62571942,19.8223424 8.36428933,19.2905812 9.04793038,18.982914 C9.15695934,18.2041316 9.46636585,17.67311 9.80965974,17.3720991 C7.14508034,17.0681299 4.34347806,16.0341908 4.34347806,11.4177047 C4.34347806,10.1027236 4.81127124,9.02736777 5.57889406,8.18498109 C5.45513145,7.88027229 5.04332612,6.65552045 5.69602652,4.99663253 C5.69602652,4.99663253 6.70380773,4.67269454 8.99636263,6.23173856 C9.95331277,5.96400899 10.9802477,5.83088379 12.0005525,5.82644628 C13.0193839,5.83088379 14.0463189,5.96400899 15.0047424,6.23173856 C17.2958239,4.67269454 18.3013951,4.99663253 18.3013951,4.99663253 C18.9563055,6.65552045 18.5445002,7.88027229 18.4207376,8.18498109 C19.1898338,9.02736777 19.6546802,10.1027236 19.6546802,11.4177047 C19.6546802,16.0460242 16.8486579,17.064432 14.1752383,17.3624845 C14.6061973,17.7344955 14.9900087,18.4696424 14.9900087,19.5938108 C14.9900087,21.2038861 14.9752751,22.503336 14.9752751,22.8982741 C14.9752751,23.2207329 15.191123,23.5957022 15.8003591,23.4781083 C20.5652194,21.8813455 24.0003683,17.3691408 24.0003683,12.0478307 C24.0003683,5.39378938 18.6270086,0 11.9990791,0"
                           id="gh"
                        ></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
}